import React from "react";
import BlogPost from "./Template.tsx";
import "./blog.css";

const Content = () => (
  <div className="mt-10 sm:max-w-[70%] sm:mx-auto font-thin">
    <header className="mb-10 text-[1.4rem] text-center font-bold">
      Punk, growing up, and staying yourself
    </header>
    <p>
      It is the early 1970s, Paul Hollins is a teenager and takes the casual
      25-minute train ride to Leeds from his hometown Harrogate. But this time,
      he is going to a concert by a new band with his friends. He is excited but
      doesn’t know what to expect. It turns out this “small band nobody heard
      of” was Sex Pistols.
    </p>
    <p>
      Paul is one of many who devoted their teenage years to bands like Sex
      Pistols and, essentially, punk music. Mohawks, Vivienne Westwood fashion,
      Doc Martens, and bootleg albums. Anti-establishment youth who advocated
      for individual freedom. That is what the era was about. Currently known
      for its strong political outlook, anti-consumerism and anti-corporatism,
      punk has evolved a great deal in the first decade of its existence. Paul
      Hollins, now 59, recalls that at the beginning of the 1970s, the
      subculture was not political in the traditional meaning of the word.
      Coming from Harrogate, a “strange and conservative town,” which was a bit
      posh (though, he says, no one was aware of it at the time), punk gave him
      a feeling of freedom.
    </p>
    <p>
      “It was political in the sense that it was an expression of breaking out
      of certain social conditions that were around,” he says. “We didn’t feel
      we had a voice, and this group gave it to us.” Dr Victor Avilla Torres,
      Associate Lecturer in Sociology at the University of York, points out that
      any subculture has its own time and space. “There is a set of reasons that
      lead to the creation of a group,” he describes.
    </p>
    <p>
      Apart from the initial adoration for fashion and music, the late 1970s
      were filled with resistance and discontent about the surrounding world.
      According to a survey conducted by Fifty magazine on subcultures and their
      impact on people’s lives, 17% of respondents said they sought political
      allies by joining a community.
    </p>
    <p>
      By the end of the decade, punk had transformed into a socio-political
      movement. John Addison, 55, admits that for him it was very much a
      political stance. “At gatherings, there were leaflets full of information
      that encouraged action. Also, punk music itself had a strong political
      subtext,” he says. “I liked to analyse lyrics, so that also got me more
      into politics.”
    </p>
    <p>
      Punk thrived throughout the 1970s and later split up into various genres,
      like new wave, Oi! or new romantic. But did the intense belonging to the
      first rush of punk have an impact on its members' later lives?
    </p>
    <p>
      Survey respondents agree on what has been (and continues to be) influenced
      the most. An overwhelming 34% said their social life flourishes to this
      day thanks to the scene they joined. Surprisingly though, professional and
      academic lives placed second and third, respectively. Paul is a fitting
      example of that. Nowadays, he is a Professor of Cultural Studies at the
      University of Bolton and admits it was punk that led him into that
      professional direction.
    </p>
    <p>
      “I have been in academia for a long time. Some years ago, perhaps a little
      less now, it allowed you to express your concerns by writing papers or
      doing research,” he says. “So, in terms of my career, I joined an area
      where I had a voice; and that was the message that punk was always trying
      to convey.”
    </p>
    <p>
      In addition, Paul’s love for the music and art scene also infiltrated his
      profession. He is the director and trustee of the Leeds Conservatoire, as
      well as a chair of galleries and museums trust. “Now, I have that musical
      interest professionally, and it definitely came out of punk,” he admits
      proudly. His constant affiliation to punk is well-visible during our
      interview, where beneath his shirt, he is wearing a tee with the “I Am a
      Cliché” album cover, a song by the 1970s punk band, X-Rey Spex.
    </p>
    <p>
      Moreover, 21% of respondents indicated that their family life has also
      been influenced by their subculture belonging. However, the differences
      between generations can lead to funny misunderstandings. John has four
      daughters but has never desperately tried to teach them about punk. He
      recalls a moment when his eldest daughter came home wearing a Sex Pistols
      shirt. “She was looking quite provocative, and I thought ‘Oh great, now I
      have to burn it,’” he says laughing.
    </p>
    <p>
      “Other time she declared: ‘I’m listening to a band that is really extreme,
      you won’t know it, dad.’ And it turned out to be the Misfits… When I told
      her, I saw them three times, she could not believe it,” he recounts. “But
      then she got a Coldplay CD, and I was horrified. So, it’s kind of a joke
      but I think it strengthens the relationships around me.”
    </p>
    <p>
      Nevertheless, identifying with a specific scene is not as common when
      people reach their 50s, which leads to critique from the outside world.
      According to our survey, more than 85% of people feel judged by their
      peers for being a part of a subculture.
    </p>
    <p>
      The respondents have been very honest about their experience. They list
      various reasons as to why people may look down on them. “We are perceived
      as too political and needing to grow up,” “people are jealous of my large
      social circle of risk-takers,” or simply “it’s ignorance”.
    </p>
    <p>
      “People still have these stereotypes from the 1970s, that it was very
      nihilistic or violent,” John says. “And it’s quite amusing since most of
      us were not violent at all. I was a pacifist, a peace protester.” He tells
      a story about his friend from Los Angeles, Paul Rogers, 55, who plays bass
      in The Wraith, an LA Death Rock band (one of the many sub-genres that
      emerged from punk), and has people constantly tell him he is too old to be
      in a band.
    </p>
    <p>
      “But he started doing this when he was 13 and people said he was too
      young, and now they say he’s too old,” John narrates. “It almost seems
      like you can do it between the ages of 15 to 25, and then you have to grow
      up. You just can’t please everyone.” Dr Avilla Torres suggests that this
      is what happens with subcultures by nature. “By its own definition, such
      groups will be in a position of challenging the mainstream culture,” he
      points out. “So, to people on the outside, it is always going to be
      something irregular.”
    </p>
    <p>
      But despite this judgement, according to 97% of respondents, subcultures
      turned out to be of immense help during lockdowns. More than 88% of people
      have engaged in online activities in their communities. They mention
      numerous examples, from social media to book-sharing and discussing issues
      like the Black Lives Matter protests, to the publishing of fanzines (a
      non-professional publication whose name comes from a blend of words “fan”
      and “magazine”). Subcultures have their own code, a way of communicating.
      People find it easier to seek support from friends within a specific group
      because there is a shared identity and affinity. It helps at times when
      more understanding and empathy are needed.
    </p>
    <p>
      “Community, in general, is helpful, but especially during a global
      pandemic,” says Dr Avilla Torres. “People can express their needs. And it
      is safer to do so amongst like-minded people.” So, while people may still
      be prejudiced towards subcultures like punk, all it might take is opening
      their minds to different ways of living.
    </p>
    <p>
      “I would suggest stepping out of the media brainwash and thinking for
      yourself,” says John. “Punk is all about thinking for yourself. People
      wonder why we don’t grow out of it, but in reality, we grow up with it.”
      As one of the survey respondents concluded: “For as long as there is
      alterophobia, the communities within subcultures will remain important
      cornerstones for people’s happiness. They can save lives.”
    </p>
  </div>
);

const Description = () => (
  <>
    <p className="font-thin text-sm">
      In 2018, for one of my final classes at City, I worked on a magazine
      supposed to answer to a niche in the market. Eventually, with my team, we
      decided to target people in their fifties with a magazine (fittingly)
      titled Fifty.
    </p>
    <p className="font-thin text-sm">
      Amongst others, it featured one of my investigative features about former
      punk fans and where they are now. My conversations with several
      interviewees went beyond one music genre, though; their focal point was
      the value of subcultures for their communities.
    </p>
    <p className="font-thin text-sm -mb-8">
      After finding most interviewees online, it was wonderful to have total
      strangers open up to me about some of the most formative years of their
      lives and see where their journey took them.
    </p>
  </>
);

const Punk = () => {
  return (
    <BlogPost
      title="What even is punk?"
      description={<Description />}
      image="punk"
      content={<Content />}
    />
  );
};

export default Punk;
